import { ReactNode } from 'react';

interface HeroStatsProps {
  label: ReactNode;
  value: string;
  modalId: string;
  footNote?: string;
}

export const heroStats: HeroStatsProps[] = [
  {
    label: 'Annualized distribution rate',
    value: `7.1%`,
    modalId: 'annualized-distribution-rate-modal',
  },
  {
    label: 'Assets under management',
    value: `$147M+`,
    modalId: '',
    footNote: 'as of December 31, 2024',
  },
];
