import React, { useState } from 'react';
import {
  TextBlock,
  MarketingCinematicContainer,
  DesktopOnly,
  MobileOnly,
  Paragraph,
  Tab,
  TabList,
} from '@yieldstreet/ui-kit';

import assetClassesBanner from 'images/home/asset-classes-banner-desktop.svg';
import assetClassesBannerMobile from 'images/home/asset-classes-banner-mobile.svg';
import capitalComposition from 'images/home/capital-composition.svg';
import capitalCompositionMobile from 'images/home/capital-composition-mobile.svg';

import {
  AssetClassesImage,
  AssetClassesWrapper,
  AssetClassesSection,
  TabsWrapper,
  TabVisible,
} from './AssetClasses.style';

export const AssetClasses = () => {
  const [active, setActive] = useState(0);
  const handleChange = (_event: React.SyntheticEvent, index: number) => setActive(index);

  return (
    <AssetClassesSection>
      <TextBlock heading="Portfolio composition" align="center" />
      <TabsWrapper>
        <TabList activeIndex={active} onChange={handleChange}>
          <Tab title="Industry" />
          <Tab title="Capital position" />
        </TabList>
      </TabsWrapper>
      <MarketingCinematicContainer withBorder>
        <TabVisible isActive={active === 0}>
          <AssetClassesWrapper>
            <DesktopOnly>
              <AssetClassesImage src={assetClassesBanner} />
            </DesktopOnly>
            <MobileOnly>
              <AssetClassesImage src={assetClassesBannerMobile} />
            </MobileOnly>
            <Paragraph marginTop="xl" size="small">
              As of December 31, 2024. Numbers presented are rounded to the nearest decimal.
              Holdings are subject to change, and may not reflect the current or future position of
              the portfolio.
            </Paragraph>
          </AssetClassesWrapper>
        </TabVisible>

        <TabVisible isActive={active === 1}>
          <AssetClassesWrapper>
            <DesktopOnly>
              <AssetClassesImage src={capitalComposition} />
            </DesktopOnly>
            <MobileOnly>
              <AssetClassesImage src={capitalCompositionMobile} />
            </MobileOnly>
            <Paragraph marginTop="xl" size="small">
              As of December 31, 2024. Numbers presented are rounded to the nearest decimal.
              Holdings are subject to change, and may not reflect the current or future position of
              the portfolio.
            </Paragraph>
          </AssetClassesWrapper>
        </TabVisible>
      </MarketingCinematicContainer>
    </AssetClassesSection>
  );
};
