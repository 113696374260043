interface HoldingProps {
  id: string;
  type: string;
  description: string;
  percentage: string;
}

export const holdingsData: HoldingProps[] = [
  {
    id: '1',
    type: 'Transportation',
    description: 'Participation in the purchase and leasing of two commercial aircrafts.',
    percentage: '9.32%',
  },
  {
    id: '2',
    type: 'Residential Real Estate',
    description: 'First mortgage loan secured by a 49-unit multi-family property in Phoenix, AZ.',
    percentage: '4.12%',
  },
  {
    id: '3',
    type: 'Residential Real Estate',
    description:
      'A junior mortgage and mezzanine loan primarily secured by a substantially completed multi-family development and parcel of land in Harrison, New Jersey.',
    percentage: '4.07%',
  },
  {
    id: '4',
    type: 'Consumer',
    description: 'Consumer installment loans secured by 12K+ powersports vehicles.',
    percentage: '3.96%',
  },
  {
    id: '5',
    type: 'Commercial Real Estate',
    description:
      'A levered mortgage and mezzanine loan secured by 65 newly-built units in a condominium building located in North Bergen, NJ.',
    percentage: '3.88%',
  },
  {
    id: '6',
    type: 'Legal Finance',
    description: "Commitment to invest in Parabellum's litigation finance fund.",
    percentage: '3.68%',
  },
  {
    id: '7',
    type: 'Consumer',
    description: 'Consumer loans secured by powersports vehicles.',
    percentage: '3.51%',
  },
  {
    id: '8',
    type: 'Residential Real Estate',
    description: 'First mortgage loan secured by land in Edgewater, Miami.',
    percentage: '3.44%',
  },
  {
    id: '9',
    type: 'Residential Real Estate',
    description: 'First mortgage loan secured by a 7-story luxury condominium building in NYC.',
    percentage: '2.96%',
  },
  {
    id: '10',
    type: 'Third Party Fund',
    description: 'Investment in ORCC (non-traded BDC)',
    percentage: '2.48%',
  },
];
